import React from 'react';
import '../css/Profile.css';
import profilePic from '../assets/test.jpg'; // Update path if needed

const Profile = () => {
  return (
    <section id="profile" className="profile-section">
      <div className="profile-content">
        <h1>Hello, I’m Onur Korkmaz</h1>
        <h2>Full Stack Developer</h2>
        <p>
          Crafting secure, scalable apps in Python & JavaScript. I embrace TDD 
          and Agile to deliver robust solutions that tackle real-world problems.
        </p>
      </div>

      <div className="profile-image">
        {/* Blurred BG layer */}
        <div className="image-bg"></div>
        {/* Actual profile photo */}
        <img className="foreground-image" src={profilePic} alt="Profile" />
      </div>
    </section>
  );
};

export default Profile;
