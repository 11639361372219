import React from 'react';
import ReactGA4 from 'react-ga4';
import './App.css';
import Header from './components/Header';
import Profile from './components/Profile';
import Experience from './components/Experience';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Education from './components/Education';
import Contact from './components/Contact';

function App() {
  React.useEffect(() => {
    // Initialize GA4
    ReactGA4.initialize("G-ZJ80T5Q9Q2"); // Your Measurement ID
    ReactGA4.send("pageview"); // Send page view for the initial page load
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <Profile />
        <Experience />
        <Skills />
        <Projects />
        <Education />
        <Contact />
      </main>
    </div>
  );
}

export default App;
