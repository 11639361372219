import React from 'react';
import { FaBriefcase, FaLaptopCode, FaUserGraduate } from 'react-icons/fa';
import '../css/Experience.css';

const Experience = () => {
  return (
    <section id="experience" className="experience-section">
      <h1>Work Experience</h1>

      <div className="experience-grid">
        {/* CARD #1 */}
        <div className="job-card">
          <div className="card-header">
            <span className="job-icon">
              <FaBriefcase />
            </span>
            <h2>Freelance Full Stack Developer</h2>
            <span className="job-dates">May 2023 - Present | Toronto, Canada</span>
          </div>
          <div className="card-body">
            <ul>
            <li>
                <strong>Auto Mechanic Repair </strong> Developed a landing page  using 
                React.
              </li>
              <li>Live at: <a href="https://swordgarage.com" target='_blank'>[Sword Garage]</a></li>

              <li>
                <strong>Mechanic Pro App:</strong> Developed a mobile app using 
                React Native (FE) & Flask (BE). Boosted workflow efficiency by 40%.
              </li>
              <li>Published at: <a href="https://github.com/ozXoz/py-react-native" target='_blank'>[GitHub]</a></li>
              <li>
                <strong>SaaS Application:</strong> Responsive SaaS platform 
                (React + Flask), supporting 500+ concurrent users.
              </li>
              <li>Live at: <a href="https://pyfrontend.vercel.app/" target='_blank'>[NexyraTeach]</a></li>
              <li>
                <strong>TrioTechShop, TrioTechInvoice, TrioTechChat:</strong> 
                Node + React e-commerce, invoice automation, real-time messaging.
              </li>
              <li>
                Live at: <a href="https://www.triotech.shop/" target='_blank'>[Shop]</a>, 
                <a href="https://www.trioteachinvoice.today/" target='_blank'>[Invoice]</a>, 
                <a href="https://www.triotech.live/" target='_blank'>[Chat]</a>
              </li>
            </ul>
          </div>
        </div>
        

        {/* CARD #2 */}
        <div className="job-card">
          <div className="card-header">
            <span className="job-icon">
              <FaLaptopCode />
            </span>
            <h2>Junior Full Stack Developer - HeraSoft</h2>
            <span className="job-dates">Sept 2022 - April 2023 | Izmir, Turkey</span>
          </div>
          <div className="card-body">
            <ul>
              <li>Developed web/mobile apps with Node, React, MongoDB, SQL.</li>
              <li>Improved security with custom middleware & Firebase auth.</li>
              <li>Delivered features via Agile workflows.</li>
            </ul>
          </div>
        </div>

        {/* CARD #3 */}
        <div className="job-card">
          <div className="card-header">
            <span className="job-icon">
              <FaUserGraduate />
            </span>
            <h2>Full Stack Developer – Intern</h2>
            <span className="job-dates">May 2022 - August 2022 | HeraSoft, Izmir</span>
          </div>
          <div className="card-body">
            <ul>
              <li>Built responsive sites & mobile apps with React & Node.</li>
              <li>Optimized DB performance with MongoDB.</li>
              <li>Reduced load times by 30% via debugging & optimization.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
