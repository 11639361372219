import React from 'react';
import '../css/Education.css';

const Education = () => {
  return (
    <section id="education" className="education-section">
      <h1>Education</h1>
      <div className="edu-details">
        <h2>Computer Programming And Analysis - Advanced Diploma</h2>
        <h3>George Brown College</h3>
      </div>
    </section>
  );
};

export default Education;
