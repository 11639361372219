import React, { useState } from 'react';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaGithub, FaLinkedin } from 'react-icons/fa';
import '../css/Contact.css';

const Contact = () => {
  // Simple local state for form inputs
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  // Handle changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would integrate your email-sending logic
    // e.g., using EmailJS, Formspree, or a custom server endpoint
    console.log('Form submitted:', formData);
    alert('Form submitted! (Implement your email logic here)');
    // Reset form
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <section id="contact" className="contact-section">
      <h1>Contact Me</h1>

      {/* Contact Info & Socials */}
      <div className="contact-container">
        <div className="info-block">
          <p>
            <FaEnvelope className="info-icon" /> 
            Email: <a href="mailto:honourkorkmaz@gmail.com">honourkorkmaz@gmail.com</a>
          </p>
          <p>
            <FaPhone className="info-icon" />
            Phone: 647-607-3838
          </p>
          <p>
            <FaMapMarkerAlt className="info-icon" />
            Location: Toronto, CA
          </p>
          <p>
            <FaGithub className="info-icon" />
            GitHub: <a href="https://github.com/ozXoz" target="_blank" rel="noreferrer">
              github.com/ozXoz
            </a>
          </p>
          <p>
            <FaLinkedin className="info-icon" />
            LinkedIn: <a 
              href="https://linkedin.com/in/onur-korkmaz" 
              target="_blank" 
              rel="noreferrer"
            >
              linkedin.com/in/onur-korkmaz
            </a>
          </p>
          <p>
            Portfolio: <a 
              href="http://www.ozkorkmaz.ca" 
              target="_blank" 
              rel="noreferrer"
            >
              ozkorkmaz.ca
            </a>
          </p>
        </div>

        {/* Contact Form */}
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input 
              type="text" 
              name="name" 
              id="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input 
              type="email" 
              name="email" 
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea 
              name="message" 
              id="message" 
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <button type="submit" className="submit-btn">Send Message</button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
