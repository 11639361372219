import React from 'react';
import { FaCode, FaServer, FaDatabase, FaCogs } from 'react-icons/fa';
import '../css/Skills.css';

const Skills = () => {
  return (
    <section id="skills" className="skills-section">
      <h1>Skills</h1>

      <div className="skills-grid">
        {/* Skill Card #1 */}
        <div className="skill-card">
          <div className="skill-icon">
            <FaCode />
          </div>
          <div className="skill-info">
            <h2>Programming</h2>
            <p>Python (Flask), JavaScript (Node.js, React.js, Angular)</p>
          </div>
        </div>

        {/* Skill Card #2 */}
        <div className="skill-card">
          <div className="skill-icon">
            <FaCogs />
          </div>
          <div className="skill-info">
            <h2>Frameworks & Libraries</h2>
            <p>React Native, Bootstrap, Apollo Server, WebSocket</p>
          </div>
        </div>

        {/* Skill Card #3 */}
        <div className="skill-card">
          <div className="skill-icon">
            <FaServer />
          </div>
          <div className="skill-info">
            <h2>Backend Development</h2>
            <p>Flask, Node.js, RESTful APIs, JWT Authentication</p>
          </div>
        </div>

        {/* Skill Card #4 */}
        <div className="skill-card">
          <div className="skill-icon">
            <FaDatabase />
          </div>
          <div className="skill-info">
            <h2>Databases</h2>
            <p>MongoDB, Firebase, SQL, GraphQL</p>
          </div>
        </div>

        {/* Skill Card #5 */}
        <div className="skill-card">
          <div className="skill-icon">
            <FaCogs />
          </div>
          <div className="skill-info">
            <h2>Practices</h2>
            <p>Agile Methodology, TDD, System Design</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
